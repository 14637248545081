@import '/src/theme/main.scss';
.back {
  width: 100%;
  background-color: rgb(164, 182, 199);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.NotF {
  border-radius: 4%;
  background-color: rgb(198, 245, 242);
  margin: 16px;
  text-align: center;
  width: 70%;
  box-shadow: rgba(1, 21, 22, 0.5) 0px 2px 10px;
  @media (max-width: 600px) {
    width: 80%;
    height: 50%;
  }
  &__Media {
    border-radius: 4%;
    margin-top: 25px;
    width: 93%;
    text-align: center;
    box-shadow: rgba(1, 21, 22, 0.5) 0px 2px 10px;
    @media (max-width: 600px) {
      width: 85%;
    }
  }
  &__Content {
    font-weight: 700;
    text-align: center;
    padding: 35px;
    color: rgb(46, 48, 78);
    @media (max-width: 600px) {
      font-weight: 400;
    }
  }
}
.ComebackHome {
  align-self: flex-end;
  font-weight: 700;
  font-size: large;
  margin: 30px;
  &__Media {
    width: 200px;
  }
}
