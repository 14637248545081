@import "./fonts.scss";
@import "./colors.scss";
@import "./settings.scss";
@import "font-awesome/css/font-awesome.min.css";
@import "./anime_colors";

// reset common styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  font-family: Vazir, sans-serif;
  background-color: #edf6fc44;
}

// skeleton styles
$color-highlight: lighten($c-gray-95, 7%);
.skeleton {
  position: relative;
  overflow: hidden;
  background: $c-gray-95;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      $c-gray-95,
      $color-highlight,
      $c-gray-95
    );
    animation: skeleton-progress 1s linear forwards infinite;
    will-change: transform;
  }
}

@keyframes skeleton-progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@function color($color-name) {
  @return var(--color-#{$color-name});
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
