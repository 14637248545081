@import "/src/theme/main.scss";

// $mal-card-color: rgba(0, 102, 255, 0.247);
$mal-card-color: #255ead33;

.mal-card {
  // border: 1px solid blue;
  display: block;
  position: relative;
  height: 352px;
  width: 334px;
  overflow: hidden;
  box-shadow: 0px 20px 20px -17px $mal-card-color;
  -webkit-box-shadow: 0px 20px 20px -17px $mal-card-color;
  -moz-box-shadow: 0px 20px 20px -17px $mal-card-color;
  &:hover {
    -webkit-box-shadow: 0px 20px 35px -16px $mal-card-color;
    -moz-box-shadow: 0px 20px 35px -16px $mal-card-color;
    box-shadow: 0px 20px 35px -16px $mal-card-color;
    transform: translateY(-5px);
    transition: all 0.3s;
  }
  &__title {
    border-top: 2px solid #255dad;
    width: 334px;
    display: table-cell;
    height: 40px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    a {
      color: rgb(49, 63, 75);
      &:hover {
        color: #255dad;
      }
    }
  }

  &__prodsrc {
    // background-color: $mal-card-color;
    background: radial-gradient(#ffffff, $mal-card-color);

    border-bottom: 2px dotted #255dad;
    border-top: 2px dotted #255dad;
    display: flex;
    flex-direction: row;
    padding: 3px 0;
    text-align: center;
    justify-content: center;
    font-size: 11px;
    .producer {
      a {
        color: rgb(15, 70, 119);
      }
      padding-right: 8px;
    }
    .eps {
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      padding: 0 8px;
    }

    .source {
      color: #7f7f7f;
      padding-left: 8px;
    }
  }

  &__genres {
    background-color: #f9f9f9;
    display: table-cell;
    margin: 0;
    width: 334px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    .MuiChip-root {
      margin: 3px;
      background-color: rgba($mal-card-color, 0.1);
      font-size: 9px;
      color: rgb(77, 75, 75);
      cursor: pointer;
      &:hover {
        background-color: $mal-card-color;
        color: #255dad;
      }
      &:active {
        background-color: $mal-card-color;
        color: #255dad;
      }
    }
  }

  &__image {
    background-color: #f9f9f9;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    height: 242px;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;
    -ms-interpolation-mode: bicubic;
    position: relative;
    width: 167px;
  }

  &__synopsis {
    display: inline-block;
    font-size: 11px;
    height: 230px;
    line-height: 1.4em;
    padding: 6px 4px 4px 8px;
    width: 155px;
    float: left;
    text-align: right;
    direction: rtl;
    line-height: 1.8em;
    overflow: hidden;
    @include scrollbars(10px, $mal-card-color, white);
    &:hover {
      overflow-y: scroll;
    }
  }

  &__information {
    background-color: white;
    bottom: 0;
    display: table;
    height: 20px;
    left: 0;
    position: absolute;
    width: 334px;
    z-index: 2;
    .info {
      background: radial-gradient(#ffffff, $mal-card-color);
      border-bottom: 2px solid #255dad;
      color: #585858;
      display: table-cell;
      font-size: 10px;
      font-weight: 700;
      height: 20px;
      line-height: 1em;
      padding-top: 4px;
      text-align: center;
    }
    .release-date {
      width: 167px;
    }
  }
}

.mr4 {
  margin-right: 4px;
}
.mt4 {
  margin-top: 4px;
}
.aw-card {
  &__poster {
    background-color: #f6f6f6;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    display: block;
    float: left;
    height: 170px;
    margin-right: 7px;
    opacity: 1;
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    width: 124px;
  }
  &__title {
    // background-image: url(/images/image_box_shadow_bottom.png?v=1634263200);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    color: #fff;
    display: block;
    font-family: Avenir, lucida grande, tahoma, verdana, arial, sans-serif;
    font-size: 11px;
    padding: 15px 5px 5px;
    position: absolute;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 80%) 1px 1px 0;
    width: calc(100% - 5px - 5px);
  }
}

// $wide-card-color: rgb(241, 142, 76);
$wide-card-color: rgb(163, 160, 192);

.wide-card {
  display: flex;
  max-width: 610px;
  height: 64px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: rgba($wide-card-color, 0.1);
  }
  &__image {
    img {
      height: 64px;
      opacity: 1;
    }
  }
  &__title {
    padding-left: 10px;
    padding-top: 5px;
    width: 300px;
    height: 64px;
    .title-link {
      color: $wide-card-color;
    }
    .genres {
      padding-top: 3px;
      .MuiChip-root {
        margin: 3px;
        background-color: $wide-card-color;
        font-size: 10px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        &:hover {
          background-color: rgba($wide-card-color, 0.15);
          color: $wide-card-color;
        }
        &:active {
          background-color: rgba($wide-card-color, 0.5);
          color: $wide-card-color;
        }
      }
    }
  }

  &__score {
    display: flex;
    margin-right: auto;
    flex-direction: column;
    width: 100px;
    margin: auto;
  }
  &__time {
    display: flex;
    margin-right: auto;
    flex-direction: column;
    margin: auto;
  }
}
.wide-card-layout {
  max-width: 610px;
  width: 610px;
  &__days {
    display: flex;
    flex-direction: row;
    direction: rtl;
    margin-right: auto;
    .day {
      border: 2px solid black;
      border-radius: 10px;
      margin: 3px;
      cursor: pointer;
      padding: 0px 2px;
    }
    .today {
      border: 2px solid $wide-card-color;
    }
  }

  &__detailCard {
    display: flex;
    max-width: 610px;
    position: relative;
    .close-btn {
      margin-left: auto;
      position: absolute;
      right: 0px;
      top: 0;
      z-index: 100;
      .MuiSvgIcon-root {
        font-size: 40px;
        color: white;
      }
    }
    .image {
      img {
        width: 190px;
        max-height: 280px;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      .title {
      }
    }
  }
}

.card-copyshode {
  background: url(https://api.awdl.ml/images/anime/1347/117616.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  max-width: 610px;
  height: 275px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  // @media (max-width: 520px) {
  //   background: rgba(0, 0, 0, 0.3);
  //   display: flex;
  //   flex-direction: column;
  //   height: auto;
  //   box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.2);
  // }
  .movie_image {
    min-width: 200px;
    padding: 0px 10px;
    display: flex;
    position: relative;
    z-index: 99;
    // @media (max-width: 520px) {
    //   display: flex;
    //   flex-basis: auto;
    //   margin: 0 auto;
    //   height: 200px;
    //   padding: 10px 0;
    //   margin-bottom: 25px;
    //   overflow: hidden;
    // }
    .movie_poster {
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }
  .center {
    display: flex;
    align-items: center;
    vertical-align: center;
    position: relative;
    z-index: 99;
    // @media (max-width: 520px) {
    //   padding: 10px 0px;
    // }
  }
  .about_movie {
    flex-basis: 100%;
    padding: 0px 10px;
    position: relative;
    z-index: 99;
    // @media (max-width: 520px) {
    //   align-items: center;
    //   text-align: center;
    // }
    h3 {
      color: #fff;
      font-size: 25px;
      margin-bottom: 10px;
      margin-top: 0px;
      text-align: center;
    }
    .movie_info {
      display: flex;
      justify-content: space-between;
      direction: rtl;
      // @media (max-width: 520px) {
      //   justify-content: space-around;
      // }
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
      .MuiChip-root {
        margin: 3px;
        background-color: $wide-card-color;
        font-size: 10px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        &:hover {
          background-color: white;
          color: $wide-card-color;
        }
        &:active {
          background-color: white;
          color: $wide-card-color;
        }
      }
    }
    .movie_desc {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;
      color: rgba(255, 255, 255, 1);
      position: relative;
      z-index: 99;
      direction: rtl;
    }
  }
  /* wawy svg */
  .wavy {
    position: absolute;
    bottom: calc(0px);
    fill: $wide-card-color;
    transform: rotate(180deg);
    z-index: 0;
    height: 650px;
  }
  .watch {
    position: relative;
    z-index: 99;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-family: Vazir;
    transition: all 0.3s;
    margin: 0 10px;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
