@import "/src/theme/main.scss";
@import url("https://unpkg.com/plyr/dist/plyr.css");
.plyr {
  min-width: 640px;
}

.watch-anime {
  display: flex;
  margin: 100px;
  @media (max-width: 760px) {
    flex-direction: column;
  }

  .player {
    max-width: 900px;
  }

  .player-options {
    margin: 50px;
    @media (max-width: 760px) {
      margin: 10px;
    }
  }
}
