@import "/src/theme/main.scss";

.anime-page {
  .banner {
    background-position: 50% 35%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    margin-top: -58px;
    @media (max-width: 760px) {
      height: 210px;
      margin-top: 0;
    }
    .shadow {
      background: linear-gradient(
        180deg,
        rgba(6, 13, 34, 0) 40%,
        rgba(6, 13, 34, 0.6)
      );
      height: 100%;
      width: 100%;
    }
  }

  .header {
    position: relative;
    .container {
      margin: 0 auto;
      min-width: 320px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: auto 215px;
      @media (min-width: 1040px) and (max-width: 1540px) {
        max-width: 1140px;
        padding-left: 50px;
        padding-right: 50px;
      }
      .cover-wrap-inner {
        @media (max-width: 760px) {
          display: grid;
          grid-template-columns: 150px auto;
          grid-gap: 20px;
          align-items: flex-end;
        }
      }
      .cover-wrap {
        position: relative;
      }
      .overlap-banner {
        margin-top: -125px;
      }

      .cover {
        background-color: rgba(212, 230, 245, 0.5);
        border-radius: 2px;
        box-shadow: 0 0 29px rgba(49, 54, 68, 0.25);
        margin-top: 15px;
        width: 100%;
      }
      .content {
        display: inline-grid;
        grid-template-rows: -webkit-min-content -webkit-min-content auto;
        grid-template-rows: min-content min-content auto;
        padding-top: 25px;
        .summary {
          margin-top: 10px;
          .description {
            color: rgb(122, 133, 143);
            font-size: 12;
            line-height: 1.5;
            margin: 0;
            max-width: 900px;
            padding: 15px 0;
            transition: 0.2s;
          }
        }
      }
      h1 {
        direction: ltr;
        text-align: right;
        @media (max-width: 760px) {
          text-align: left;
          font-weight: 500;
          margin-bottom: 20px;
        }
      }
    }
  }

  .anime-details {
    max-width: 1350px;
    @media (max-width: 760px) {
      margin-top: -40px;
    }

    // @media (min-width: 760px) {
    //   margin-right: 200px;
    // }

    padding: 10px 10px;

    &__data {
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      @media (max-width: 760px) {
        flex-direction: column;
      }
      a {
        color: color(anime-cover);
        filter: brightness(70%);
      }
    }
    .section {
      padding-left: 30px;

      @media (min-width: 1100px) {
        padding-left: 130px;
      }
      padding-right: 10px;
      line-height: 2rem;
      div {
        text-align: right;
        display: flex;
        span {
          &.label {
            min-width: 110px;
          }
          display: inline-block;
          text-align: right;
        }
      }

      margin-top: 20px;
      flex-direction: column;
      font-size: 10;
    }

    .summary {
      margin-bottom: 10px;

      .description {
        color: rgb(122, 133, 143);
        font-size: 14px;
        line-height: 1.8;
        margin: 0;
        max-width: 900px;
        padding: 15px 0;
        transition: 0.2s;
      }
    }
  }
}

.tg_btn {
  width: 190px;
  height: 50px;
  @media (max-width: 760px) {
    margin-left: auto;
    margin-bottom: 10px;
  }
}

.download-box {
  margin-top: 50px;
  margin-bottom: 200px;
  .download-section {
    margin: 10px 0px;
    .MuiTypography-root {
      width: 25%;
      flex-shrink: 0;
      @media (max-width: 760px) {
        width: 33%;
        padding: 0px 10px;
      }
    }

    @media (min-width: 1000px) {
      margin: 10px 50px;
      max-width: 1000px;
    }
    .epi {
      border: 3px solid rgb(223, 221, 221);
      padding: 10px;
      display: flex;
      align-items: center;
      span:first-of-type {
        margin-right: auto;
      }
    }
  }
}

$blue-color: rgb(7, 79, 146);
.online-play-btn {
  display: flex;
  justify-content: center;
  text-align: center;
  border: 2px solid $blue-color;
  border-radius: 6px;
  color: $blue-color;
  a {
    color: $blue-color;
    padding: 5px;
    &:hover {
      color: rgba($blue-color, 0.6);
    }
  }
  &:hover {
    border-color: rgba($blue-color, 0.6);
  }

}

.file-info-btn{
  cursor: pointer;
  color: color(anime-cover);
  filter: brightness(70%);
  margin-right: 10px;

  @media (min-width: 760px) {
    margin-left: 60px;
    margin-right : 20px;

  }
}


.file-modal{
  &__data{
    .label{
      text-align: right;
    }
    .value{
      direction: ltr;
      text-align: left;
    }
  }
}