@import "/src/theme/main.scss";

.home-page-slider {
  margin: 15px 10px 0px;
  h3 {
    border-right: 3px solid rgb(37, 93, 173);
    padding-right: 15px;
    
  }
  @media screen and (min-width: 900px) {
    margin: 15px 60px 0px;
  }
}
