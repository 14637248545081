.carousal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;

  .swiper-container {
    position: static;
  }

  .swiper-slide {
    margin: 10px 0;
    padding: 10px 0;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-prev {
    width: 56px;
    height: 56px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    padding: 8px;
    background-color: #fff;
    padding: 10px;
    &::after {
      font-weight: bolder;
      color: #666;
      font-size: 24px;
    }
  }
  .swiper-button-next {
    width: 56px;
    height: 56px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    padding: 8px;
    background-color: #fff;
    padding: 10px;
    &::after {
      font-weight: bolder;
      color: #666;
      font-size: 24px;
    }
  }
}

.anilist-card {
  width: 185px;
  cursor: pointer;
  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 2px solid rgb(37, 93, 173);
    img {
      width: 100%;
      height: 265px;
    }
  }
  .content {
    color: rgb(116, 136, 153);
    .title {
      height: 42px;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      margin-top: 10px;
      overflow: hidden;
      transition: color 0.2s ease;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      direction: ltr;
    }
    &:hover {
      color: rgb(37, 93, 173);
    }
    &:active {
      color: rgb(37, 93, 173);
    }
  }
}

.anilist-card-layout {
  @media (min-width: 1000px) {
    max-width: 1000px;
  }
  @media (min-width: 850px) and (max-width: 1000px) {
    max-width: 850px;
  }
  @media (min-width: 600px) and (max-width: 850px) {
    max-width: 600px;
  }
  @media (max-width: 600px) {
    max-width: 400px;
  }
  margin: auto;
}
